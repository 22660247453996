import "./style.css";

import outsideButtonsChange from "../../img/outsideButtonsChange.png";
import outsideButtonsPosition from "../../img/outsideButtonsPosition.png";
import outsideButtonsVisibility from "../../img/outsideButtonsVisibility.png";
import outsideButtonsDelete from "../../img/outsideButtonsDelete.png";
import arrowButtonBack from "../../img/arrowButtonBack.png";

import mainRightLine from "../../img/main-right-line.png";

import { useContext, useEffect, useState } from "react";
import img11 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img5 from "./5.png";
import img6 from "./6.png";
import img7 from "./7.png";
import img8 from "./8.png";
import img9 from "./9.png";
import img10 from "./10.png";
import img111 from "./11.png";
import img13 from "./13.png";
import { getBase64 } from "../../const";
import { json } from "react-router-dom";
import { Context } from "../Context";
export const DBWantToJoinContainer = () => {
  const [arrData, setArrData] = useState();
  const [choosenAddElement, setChoosenAddElement] = useState("");
  const [choosenAddElementId, setChoosenAddElementId] = useState(10000);
  const [showModalChange, isShowModalChange] = useState(false);
  const [showModalDelete, isShowModalDelete] = useState(false);
  const [arrLength, setArrLength] = useState(0);
  const [errorPosition, setErrorPosition] = useState(false);
  let urlParams = new URLSearchParams(window.location.search);
  let myParam = urlParams.get("key");
  console.log(arrData);
  const getElements = () => {
    fetch(
      "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
        myParam +
        "&type=content"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setArrData(data);
        setArrLength(data.length);
      });
  };
  let admin = useContext(Context);
  const addNewElement = () => {
    if (choosenAddElement == "TABLE") {
      let leftPos = document.getElementsByClassName("input_table1");
      let rightPos = document.getElementsByClassName("input_table2");
      let sendArr = [];
      for (let i = 0; i < leftPos.length; i++) {
        sendArr.push({
          COLUMN1: leftPos[i].value,
          COLUMN2: rightPos[i].value,
        });
      }
      console.log(sendArr);
      fetch(
        "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
          myParam +
          "&type=content",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            type: choosenAddElement,
            table: sendArr,
          }),
        }
      ).then((response) => {
        getElements();
        setChoosenAddElement("");
        alert("Элемент успешно добавлен");
      });
    }
    if (choosenAddElement == "IMG1" || choosenAddElement == "IMG2") {
      let file = window.document.getElementById("changeImg").files[0];
      console.log(file);
      let postObj = new Object();
      getBase64(file).then((data) => {
        postObj.imgBase64 = data;
        postObj.imgExtension = file.name.split(".").pop();
        fetch(
          "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
            myParam +
            "&type=content",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Accept: "application/json",
            },
            body: JSON.stringify({
              type: choosenAddElement,
              img: postObj,
            }),
          }
        ).then((response) => {
          getElements();
          setChoosenAddElement("");
          alert("Элемент успешно добавлен");
        });
      });
    }
    if (
      choosenAddElement == "TITLE" ||
      choosenAddElement == "SUBTITLE" ||
      choosenAddElement == "TEXT1" ||
      choosenAddElement == "TEXT2" ||
      choosenAddElement == "VIDEO"
    ) {
      let value1 = window.document.getElementById("changeTitle").value;
      fetch(
        "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
          myParam +
          "&type=content",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            type: choosenAddElement,
            value: value1,
          }),
        }
      ).then((response) => {
        getElements();
        setChoosenAddElement("");
        alert("Элемент успешно добавлен");
      });
    }
    if (choosenAddElement == "TEXT3") {
      let title = window.document.getElementById("changeTitle").value;
      let text = window.document.getElementById("changeTitle1").value;
      fetch(
        "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
          myParam +
          "&type=content",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            type: choosenAddElement,
            textType: {
              TITLE: title,
              VALUE: text,
            },
          }),
        }
      ).then((response) => {
        getElements();
        setChoosenAddElement("");
        alert("Элемент успешно добавлен");
      });
    }
    if (choosenAddElement == "LINK") {
      let title = window.document.getElementById("changeTitle").value;
      let text = window.document.getElementById("changeTitle1").value;
      let link1 = window.document.getElementById("changeTitle2").value;
      fetch(
        "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
          myParam +
          "&type=content",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            type: choosenAddElement,
            link: {
              header: title,
              text: text,
              link_value: link1,
            },
          }),
        }
      ).then((response) => {
        getElements();
        setChoosenAddElement("");
        alert("Элемент успешно добавлен");
      });
    }
    if (choosenAddElement == "LINK2") {
      let text = window.document.getElementById("changeTitle1").value;
      let link1 = window.document.getElementById("changeTitle2").value;
      fetch(
        "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
          myParam +
          "&type=content",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            type: choosenAddElement,
            link2: {
              text: text,
              link2_value: link1,
            },
          }),
        }
      ).then((response) => {
        getElements();
        setChoosenAddElement("");
        alert("Элемент успешно добавлен");
      });
    }
    if (choosenAddElement == "ELEM_LIST") {
      let file = window.document.getElementById("changeImg").files[0];
      let title = window.document.getElementById("changeTitle").value;
      console.log(file);
      let postObj = new Object();
      getBase64(file).then((data) => {
        postObj.imgBase64 = data;
        postObj.imgExtension = file.name.split(".").pop();
        fetch(
          "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
            myParam +
            "&type=content",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Accept: "application/json",
            },
            body: JSON.stringify({
              type: choosenAddElement,
              img: postObj,
              value: title,
            }),
          }
        ).then((response) => {
          getElements();
          setChoosenAddElement("");
          alert("Элемент успешно добавлен");
        });
      });
    }
  };
  console.log(choosenAddElementId);
  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  const deleteElement = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Card/?id=${choosenAddElementId}&type=content&idParent=${myParam}`,
      { method: "DELETE" }
    ).then((response) => {
      if (response.status == 200) {
        alert("Элемент успешно удален");
        isShowModalDelete(false);
        getElements();
        setChoosenAddElementId(10000);
      }
    });
  };

  const changePositionELement = () => {
    let newPosition = window.document.getElementById("newPosition").value;
    if (newPosition.length > 0 && newPosition <= arrLength) {
      setErrorPosition(false);
      let newPos = JSON.stringify({ newPosition: newPosition });
      fetch(
        `https://cbt-version.ru/UltronAdminApi/Card/?id=${choosenAddElementId}&type=content&idParent=${myParam}`,
        {
          method: "PUT",
          body: newPos,
        }
      ).then((response) => {
        if (response.status == 200) {
          alert("Позиция успешно изменена");
          isShowModalChange(false);
          getElements();
        }
      });
    } else {
      setErrorPosition(true);
    }
  };
  function addLine() {
    let div = `<div>
    <input type="text" class="input_table1" />
    <input type="text" class="input_table2" />
  </div>`;
    document.getElementById("table_add").innerHTML += div;
  }
  const visibilityElement = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Card/?id=${choosenAddElementId}&type=content&idParent=${myParam}`,
      { method: "PUT" }
    ).then((response) => {
      getElements();
      alert("Элемент скрыт");
    });
  };

  useEffect(() => {
    getElements();
  }, []);
  return (
    <div className="dbWant_to_join_container_main">
      {showModalDelete && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Вы действительно хотите удалить этот элемент?</p>
            <div>
              <button
                onClick={() => {
                  deleteElement();
                }}
              >
                Удалить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalDelete(false);
                }}
              >
                Оставить
              </button>
            </div>
          </div>
        </div>
      )}
      {showModalChange && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Введите новую позицию элемента</p>
            <input
              type="number"
              id="newPosition"
              style={{
                border: errorPosition && "2px solid red",
              }}
            />
            <div>
              <button
                onClick={() => {
                  changePositionELement();
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalChange(false);
                }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      {choosenAddElement != "" && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            {choosenAddElement == "TITLE" && (
              <div>
                <p>Укажите заголовок</p> <input type="text" id="changeTitle" />
              </div>
            )}
            {choosenAddElement == "LINK" && (
              <div>
                <p>Укажите заголовок</p> <input type="text" id="changeTitle" />
                <p>Укажите текст</p>
                <input type="text" id="changeTitle1" />
                <p>Укажите ссылку</p>
                <input type="text" id="changeTitle2" />
              </div>
            )}
            {choosenAddElement == "LINK2" && (
              <div>
                <p>Укажите текст</p>
                <input type="text" id="changeTitle1" />
                <p>Укажите ссылку</p>
                <input type="text" id="changeTitle2" />
              </div>
            )}
            {choosenAddElement == "SUBTITLE" && (
              <div>
                <p>Укажите подзаголовок</p>
                <input type="text" id="changeTitle" />
              </div>
            )}
            {choosenAddElement == "TEXT1" && (
              <div>
                <p>Укажите текст</p>
                <input type="text" id="changeTitle" />
              </div>
            )}
            {choosenAddElement == "TEXT2" && (
              <div>
                <p>Укажите текст</p>
                <input type="text" id="changeTitle" />
              </div>
            )}
            {choosenAddElement == "TEXT3" && (
              <div>
                <p>Укажите Заголовок</p>
                <input type="text" id="changeTitle" />
                <p>Укажите текст</p>
                <input type="text" id="changeTitle1" />
              </div>
            )}
            {choosenAddElement == "IMG1" && (
              <div>
                <p>Добавьте изображение</p>
                <input type="file" id="changeImg" />
              </div>
            )}
            {choosenAddElement == "IMG2" && (
              <div>
                <p>Добавьте изображение</p>
                <input type="file" id="changeImg" />
              </div>
            )}
            {choosenAddElement == "TABLE" && (
              <div>
                <p>Заполните ячейки</p>
                <div id="table_add">
                  <div>
                    <input type="text" className="input_table1" />
                    <input type="text" className="input_table2" />
                  </div>
                </div>
                <div className="add_line" onClick={() => addLine()}>
                  Добавить строку
                </div>
              </div>
            )}
            {choosenAddElement == "ELEM_LIST" && (
              <div>
                <p>Укажите текст элемента списка</p>
                <input type="text" id="changeTitle" />
                <p>Добавьте изображение</p>
                <input type="file" id="changeImg" />
              </div>
            )}
            {choosenAddElement == "VIDEO" && (
              <div>
                <p>Укажите ссылку на видео</p>
                <input type="text" id="changeTitle" />
              </div>
            )}

            <div>
              <button
                onClick={() => {
                  addNewElement();
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  setChoosenAddElement("");
                }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="dbWant_to_join_container">
        <div className="database_title">
          Вы редактируете раздел <br />
          контента
          <img className="mainRightLine" src={mainRightLine} />
        </div>
        <div className="database_content">
          <div className="phone_page">
            <div className="phone_page_box_white_background">
              <div className="phone_page_box">
                <div className="phone_page_button_onMain">
                  <img className="arrowButtonBack" src={arrowButtonBack} />
                  <p className="phone_page_button_onMain_title text_transform">
                    база знаний
                  </p>
                </div>

                <div className="phone_page_content">
                  {arrData &&
                    arrData.length > 0 &&
                    arrData.map((elem, index) => {
                      if (elem.TYPE == "TEXT1") {
                        return (
                          <div
                            className="second_type"
                            key={index}
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                          >
                            {elem.VALUE}
                          </div>
                        );
                      }
                      if (elem.TYPE == "LINK") {
                        return (
                          <div
                            className="link_date"
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                            key={index}
                          >
                            <p>{elem.VALUE.header}</p>
                            <div>
                              <span>{elem.VALUE.text}</span>
                              <a href={elem.VALUE.link_value}>ПЕРЕЙТИ</a>
                            </div>
                          </div>
                        );
                      }
                      if (elem.TYPE == "LINK2") {
                        return (
                          <div
                            className="link_date"
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                            key={index}
                          >
                            <div>
                              <span>{elem.VALUE.text}</span>
                              <a href={elem.VALUE.link2_value}>ПЕРЕЙТИ</a>
                            </div>
                          </div>
                        );
                      }
                      if (elem.TYPE == "TEXT2") {
                        return (
                          <div
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                            className="first_type"
                            key={index}
                          >
                            {elem.VALUE}
                          </div>
                        );
                      }
                      if (elem.TYPE == "TITLE") {
                        return (
                          <p
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                            className="phone_page_title"
                            key={index}
                          >
                            {elem.VALUE}
                          </p>
                        );
                      }
                      if (elem.TYPE == "TEXT3") {
                        return (
                          <div
                            className="fourth_type"
                            key={index}
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                          >
                            <h4>{elem.VALUE.TITLE}</h4>
                            <p>{elem.VALUE.VALUE}</p>
                          </div>
                        );
                      }
                      if (elem.TYPE == "TABLE") {
                        return (
                          <div
                            className="nine_type"
                            key={index}
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                          >
                            {elem.VALUE.map((elem1, index1) => {
                              return (
                                <div
                                  key={index1}
                                  style={{
                                    background: index1 % 2 == 1 && "#EFE8FF",
                                  }}
                                >
                                  <p>{elem1.COLUMN1}</p>
                                  <span>{elem1.COLUMN2}</span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                      if (elem.TYPE == "ELEM_LIST") {
                        return (
                          <div
                            className="eit_type"
                            key={index}
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                          >
                            <img
                              src={
                                "https://cbt-version.ru/UltronAdminApi/img/" +
                                elem.VALUE.IMG
                              }
                              alt=""
                            />
                            <p>{elem.VALUE.VALUE}</p>
                          </div>
                        );
                      }
                      if (elem.TYPE == "VIDEO") {
                        let src1 = youtube_parser(elem.VALUE);

                        return (
                          <div
                            className="sixth_type"
                            key={index}
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",
                              textAlign: "center",
                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                          >
                            <div style={{ textAlign: "center" }}>
                              Редактировать
                            </div>
                            <iframe
                              src={"https://www.youtube.com/embed/" + src1}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        );
                      }
                      if (elem.TYPE == "IMG1") {
                        return (
                          <div
                            className="seven_type"
                            key={index}
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",

                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                          >
                            <img
                              src={
                                "https://cbt-version.ru/UltronAdminApi/img/" +
                                elem.VALUE
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      if (elem.TYPE == "IMG2") {
                        return (
                          <div
                            className="fiveth_type"
                            key={index}
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",

                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                          >
                            <img
                              src={
                                "https://cbt-version.ru/UltronAdminApi/img/" +
                                elem.VALUE
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      if (elem.TYPE == "SUBTITLE") {
                        return (
                          <div
                            className="third_type"
                            key={index}
                            onClick={() => {
                              setChoosenAddElementId(elem.ID);
                            }}
                            style={{
                              border:
                                choosenAddElementId == elem.ID &&
                                "3px solid #ab84fd",

                              opacity: elem.VISIBILITY == "0" && "0.5",
                            }}
                          >
                            {elem.VALUE}
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
            <div
              className="phone_page_outside_buttons"
              style={{
                opacity: choosenAddElementId == 10000 && "0.5",
                pointerEvents: choosenAddElementId == 10000 && "none",
                height: "16.5vw",
              }}
            >
              <div
                className="outside_buttons_box"
                onClick={() => isShowModalChange(true)}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsPosition}
                  />
                </div>
                <p className="outside_buttons_title text_transform">позиция</p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => visibilityElement()}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsVisibility}
                  />
                </div>
                <p className="outside_buttons_title text_transform">
                  видимость
                </p>
              </div>
              {admin && (
                <div
                  className="outside_buttons_box"
                  onClick={() => isShowModalDelete(true)}
                >
                  <div className="outside_buttons_img">
                    <img
                      className="outsideButtonsImg"
                      src={outsideButtonsDelete}
                    />
                  </div>
                  <p className="outside_buttons_title text_transform">
                    удалить
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="add__element">
            <div>
              <img
                src={img11}
                alt=""
                onClick={() => {
                  setChoosenAddElement("TITLE");
                }}
              />
              <img
                src={img2}
                onClick={() => {
                  setChoosenAddElement("SUBTITLE");
                }}
                alt=""
              />
            </div>
            <div>
              <img
                src={img3}
                onClick={() => {
                  setChoosenAddElement("TEXT1");
                }}
                alt=""
              />
              <img
                src={img4}
                onClick={() => {
                  setChoosenAddElement("ELEM_LIST");
                }}
                alt=""
              />
            </div>
            <div>
              <img
                src={img5}
                onClick={() => {
                  setChoosenAddElement("VIDEO");
                }}
                alt=""
              />
              <img
                src={img6}
                onClick={() => {
                  setChoosenAddElement("TABLE");
                }}
                alt=""
              />
            </div>
            <div>
              <img
                src={img7}
                onClick={() => {
                  setChoosenAddElement("TEXT3");
                }}
                alt=""
              />
              <img
                src={img8}
                onClick={() => {
                  setChoosenAddElement("TEXT2");
                }}
                alt=""
              />
            </div>
            <div>
              <img
                src={img9}
                onClick={() => {
                  setChoosenAddElement("IMG1");
                }}
                alt=""
              />

              <img
                src={img111}
                style={{ width: "10vw" }}
                onClick={() => {
                  setChoosenAddElement("LINK");
                }}
              />
            </div>
            <div>
              <img
                src={img13}
                style={{ width: "10vw" }}
                onClick={() => {
                  setChoosenAddElement("LINK2");
                }}
              />
              <img
                src={img10}
                onClick={() => {
                  setChoosenAddElement("IMG2");
                }}
                alt=""
                className="new"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
