import "./style.css"
import addSectionImg from "../../img/addSectionImg.png"
import addCoin from "../../img/addCoin.png"
export const DataBaseAddCoin = () =>{
    return(
        <div className="add_section">
            <p className="add_section_title text_transform">добавьте страницу</p>
            <div className="add_coin_settings">
                <p className="add_coin_settings_title text_transform">название сервиса</p>
                <div className="add_coin_settings_img_box">
                    <img src={addCoin} className="add_coin_settings_img"/>
                </div>
                <button className="add_coin_settings_button text_transform">смотреть</button>
            </div>
        </div>
    )
}