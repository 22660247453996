import "./style.css"
import addSectionImg from "../../img/addSectionImg.png"

export const DataBaseAddPageView = () =>{
    return(
        <div className="add_section">
            <p className="add_section_title text_transform">добавьте страницу</p>
            <div className="add_section_settings">
                <div className="add_section_img_placeholder">
                    <img className="addSectionImg" src={addSectionImg}/>
                </div>
                <div className="add_section_title_button_placeholder">
                    <p className="add_sectionView_title_placeholder text_transform">название страницы</p>
                    <button className="add_section_button_placeholder text_transform">смотреть</button>
                </div> 
            </div>
        </div>
    )
}