import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import "../UserTableContainer/style.css";

export const UserTableContainer = ({ nav }) => {
  const [users, setUsers] = useState([]);
  const [arrow, setArrow] = useState(1);
  const [name, setName] = useState("");
  const [nameM, setNameM] = useState("");
  const [act, setAct] = useState(false);
  const [sort, setSort] = useState("ФИО");
  const [open, setOpen] = useState(false);
  const [sortN, setSortN] = useState("down");
  const Navigate = useNavigate();
  let input = useRef(null);
  const copytext = (ref) => {
    input.current.value = ref;
    console.log(input.current);
    input.current.select();
    document.execCommand("copy");
  };
  const getUsers = () => {
    fetch("https://cbt-version.ru/UltronAdminApi/GetUsers.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      });
  };
  const changeUser = (ID, ACTIVE) => {
    let id = ID;
    let active = ACTIVE;
    let form = new FormData();
    form.append("id", id);
    form.append("active", active);
    fetch("https://ultroninfobot.store/change.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        getUsers();
      });
  };
  console.log(users);

  useEffect(() => {
    getUsers();
    document.getElementsByClassName("navbar-mainpage")[0].style.display =
      "none";
    return function show() {
      document.getElementsByClassName("navbar-mainpage")[0].style.display =
        "block";
    };
  }, []);

  return (
    <div className="utc_main" style={{ marginLeft: "0", width: nav && "auto" }}>
      <input
        type="text"
        name="fff"
        id="hidden__input"
        ref={input}
        style={{ transform: "translateX(-1000px)" }}
      />
      <div className="utc_main__table">
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "2vw" }}
        >
          <button
            className="wp_header_sections_link"
            onClick={() => Navigate(routes.welcomePage)}
            style={{ marginLeft: "51px", outline: "none", border: "none" }}
          >
            На главную
          </button>
          <input
            className="input_table"
            type="text"
            placeholder="Поиск по имени"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <label className="pokaz" htmlFor="ch" onClick={() => setAct(!act)}>
            Показать <br /> неактивированных
          </label>
          <input
            className="checkme"
            type="checkbox"
            name=""
            id="ch"
            onClick={() => setAct(!act)}
          />
          <input
            className="input_table"
            type="text"
            placeholder="Поиск по ID mavie"
            onChange={(e) => setNameM(e.target.value)}
            value={nameM}
          />
          <div className="sortmain">
            <div className="sorthead" onClick={() => setOpen(!open)}>
              {sort == "ID"
                ? "Код"
                : sort == "IDjoin"
                ? "ID пригласившего"
                : sort}
            </div>
            <div
              className="sortbody"
              style={open ? { height: "auto" } : { height: "0" }}
            >
              <div
                onClick={() => {
                  setSort("ФИО");
                  setOpen(!open);
                }}
              >
                ФИО
              </div>
              <div
                onClick={() => {
                  setSort("Телефон");
                  setOpen(!open);
                }}
              >
                Телефон
              </div>
              <div
                onClick={() => {
                  setSort("ID");
                  setOpen(!open);
                }}
              >
                Код
              </div>
              <div
                onClick={() => {
                  setSort("IDjoin");
                  setOpen(!open);
                }}
              >
                ID пригласившего
              </div>
            </div>
          </div>
          <div className="sort">
            <div
              style={
                arrow == 1 ? { background: "#ab84fd" } : { background: "#fff" }
              }
              onClick={() => {
                setSortN("down");
                setArrow(1);
              }}
            >
              ↑
            </div>
            <div
              style={
                arrow == 2 ? { background: "#ab84fd" } : { background: "#fff" }
              }
              onClick={() => {
                setSortN("up");
                setArrow(2);
              }}
            >
              ↓
            </div>
          </div>
        </div>
        <table cellSpacing="0" className="utc_main__table__main">
          <tr className="utc_main__table__header">
            <td className="utc_main__table__header__fio">ФИО </td>
            <td className="utc_main__table__header__tel">телефон </td>
            <td className="utc_main__table__header__pass">username Tg</td>
            <td className="utc_main__table__header__code">код </td>
            <td className="utc_main__table__header__reflink">реф.ссылка</td>
            <td className="utc_main__table__header__mavie">
              ID <br /> в mavie
            </td>
            <td className="utc_main__table__header__reffio">
              ID <br /> <span>пригласившего</span>{" "}
            </td>
            <td className="utc_main__table__header__act">активация</td>
          </tr>

          {users &&
            users.length > 0 &&
            !act &&
            users
              .sort((prev, next) => {
                if (sort == "IDjoin" || sort == "ID") {
                  if (sortN == "up") {
                    if (Number(prev[sort]) < Number(next[sort])) return -1;
                    if (Number(prev[sort]) < Number(next[sort])) return 1;
                  } else {
                    if (sortN == "down") {
                      if (Number(prev[sort]) > Number(next[sort])) return -1;
                      if (Number(prev[sort]) > Number(next[sort])) return 1;
                    }
                  }
                } else {
                  if (sortN == "up") {
                    if (prev[sort] < next[sort]) return -1;
                    if (prev[sort] < next[sort]) return 1;
                  } else {
                    if (sortN == "down") {
                      if (prev[sort] > next[sort]) return -1;
                      if (prev[sort] > next[sort]) return 1;
                    }
                  }
                }
              })
              .map((em) => {
                if (
                  em["ФИО"].toLowerCase().includes(name.toLowerCase()) &&
                  em["Mavie"].toLowerCase().includes(nameM.toLowerCase())
                ) {
                  return (
                    <tr className="utc_main__table__main__user" key={em["ID"]}>
                      <td className="utc_main__table__main__user__fio">
                        {em["ФИО"]}
                      </td>
                      <td className="utc_main__table__main__user__tel">
                        {em["Телефон"]}
                      </td>
                      <td className="utc_main__table__main__user__pass">
                        {em["userNameTg"].length > 0
                          ? "@" + em["userNameTg"]
                          : "-"}
                      </td>
                      <td className="utc_main__table__main__user__code">
                        {em["ID"]}
                      </td>
                      <td className="utc_main__table__main__user__reflink">
                        {em["РефСсылка"] && em["РефСсылка"].length > 15
                          ? em["РефСсылка"].substr(2, 15) + "..."
                          : "-"}{" "}
                        {
                          <div
                            className="copy"
                            onClick={() => copytext(em["РефСсылка"])}
                            style={
                              em["РефСсылка"] && em["РефСсылка"].length > 15
                                ? { visibility: "visible" }
                                : { visibility: "hidden" }
                            }
                          >
                            Скопировать
                          </div>
                        }
                      </td>
                      <td className="utc_main__table__main__user__mavie">
                        {em["Mavie"].length > 0 ? em["Mavie"] : "-"}
                      </td>
                      <td className="utc_main__table__main__user__reffio">
                        {em["IDjoin"].length > 0 ? em["IDjoin"] : "-"}
                      </td>
                      <td className="utc_main__table__main__user__act">
                        {em["АктивацияID"] == 1 ? (
                          <div
                            className="acc__active"
                            onClick={() => {
                              changeUser(em.ID, 0);
                            }}
                          >
                            Активен
                          </div>
                        ) : (
                          <div
                            className="acc__disable"
                            onClick={() => {
                              changeUser(em.ID, 1);
                            }}
                          >
                            Не активен
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}

          {users &&
            users.length > 0 &&
            act &&
            users
              .sort((prev, next) => {
                if (sort == "IDjoin" || sort == "ID") {
                  if (sortN == "up") {
                    if (Number(prev[sort]) < Number(next[sort])) return -1;
                    if (Number(prev[sort]) < Number(next[sort])) return 1;
                  } else {
                    if (sortN == "down") {
                      if (Number(prev[sort]) > Number(next[sort])) return -1;
                      if (Number(prev[sort]) > Number(next[sort])) return 1;
                    }
                  }
                } else {
                  if (sortN == "up") {
                    if (prev[sort] < next[sort]) return -1;
                    if (prev[sort] < next[sort]) return 1;
                  } else {
                    if (sortN == "down") {
                      if (prev[sort] > next[sort]) return -1;
                      if (prev[sort] > next[sort]) return 1;
                    }
                  }
                }
              })
              .map((em) => {
                if (
                  em["ФИО"].toLowerCase().includes(name.toLowerCase()) &&
                  em["Mavie"].toLowerCase().includes(nameM.toLowerCase()) &&
                  em["АктивацияID"] != 1
                ) {
                  return (
                    <tr className="utc_main__table__main__user" key={em["ID"]}>
                      <td className="utc_main__table__main__user__fio">
                        {em["ФИО"]}
                      </td>
                      <td className="utc_main__table__main__user__tel">
                        {em["Телефон"]}
                      </td>
                      <td className="utc_main__table__main__user__pass">
                        {em["username tg"]}
                      </td>
                      <td className="utc_main__table__main__user__code">
                        {em["ID"]}
                      </td>
                      <td className="utc_main__table__main__user__reflink">
                        {em["РефСсылка"] && em["РефСсылка"].length > 15
                          ? em["РефСсылка"].substr(2, 15) + "..."
                          : "-"}{" "}
                        {
                          <div
                            className="copy"
                            onClick={() => copytext(em["РефСсылка"])}
                            style={
                              em["РефСсылка"] && em["РефСсылка"].length > 15
                                ? { visibility: "visible" }
                                : { visibility: "hidden" }
                            }
                          >
                            Скопировать
                          </div>
                        }
                      </td>
                      <td className="utc_main__table__main__user__mavie">
                        {em["Mavie"].length > 0 ? em["Mavie"] : "-"}
                      </td>
                      <td className="utc_main__table__main__user__reffio">
                        {em["IDjoin"].length > 0 ? em["IDjoin"] : "-"}
                      </td>
                      <td className="utc_main__table__main__user__act">
                        {em["АктивацияID"] == 1 ? (
                          <div
                            className="acc__active"
                            onClick={() => {
                              changeUser(em.ID, 0);
                            }}
                          >
                            Активен
                          </div>
                        ) : (
                          <div
                            className="acc__disable"
                            onClick={() => {
                              changeUser(em.ID, 1);
                            }}
                          >
                            Не активен
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
        </table>
      </div>
    </div>
  );
};
