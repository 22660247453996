import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ChooseTemplate } from "./Pages/ChooseTemplate";
import { routes } from "./routes";
import { Navbar } from "./Components/Navbar";
import { WelcomPage } from "./Pages/WelcomePage";
import { UserTable } from "./Pages/UserTable";
import { DataBase } from "./Pages/DataBase";
import { DBWhereToStart } from "./Pages/DBWhereToStart";
import { DBWantToJoin } from "./Pages/DBWantToJoin";
import { DBPromotion } from "./Pages/DBPromotion";
import { DBDocuments } from "./Pages/DBDocuments";
import { DBResources } from "./Pages/DBResources";
import { MavieMain } from "./Pages/MavieMain";
import { DBBirges } from "./Pages/DBBirges";
import { DBAnalytics } from "./Pages/DBAnalytics";
import { AnimatePresence } from "framer-motion";
import "../src/fonts/stylesheet.css";
import { useEffect, useState } from "react";
import { ChooseTemplateMavie } from "./Pages/ChooseTemplateMavie";
import { WhereToStartMavie } from "./Pages/WhereToStartMavie";
import { WantToJoinMavie } from "./Pages/WantToJoinMavie";
import { Context } from "./Containers/Context";
function App() {
  const [nav, setNav] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useState(false);
  const [admin, isAdmin] = useState(false);
  function checkAuth() {
    let form = new FormData();
    form.append("tel", login);
    form.append("password", password);
    fetch("https://ultroninfobot.store/authAdmin.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data != "error") {
          setAuth(true);
          localStorage.setItem("login", login);
          localStorage.setItem("password", password);
          if (data == "85") {
            isAdmin(true);
          }
        } else {
          alert("Неверные данные для входа");
        }
      });
  }
  useEffect(() => {
    if (localStorage.login && localStorage.password) {
      let form = new FormData();
      form.append("tel", localStorage.login);
      form.append("password", localStorage.password);
      fetch("https://come-back-team.store/authAdmin.php", {
        method: "POST",
        body: form,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data != "error") {
            //if (data=='85')
            setAuth(true);
            if (data == "85") {
              isAdmin(true);
            }
          } else {
          }
        });
    }
  }, []);
  return (
    <div className="App">
      <Context.Provider value={admin}>
        {!auth && (
          <div className="autorization">
            <div className="auth__body">
              <h3>Авторизация</h3>
              <p>Логин</p>
              <input
                type="text"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              ></input>
              <p>Пароль</p>
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <div
                onClick={() => {
                  checkAuth();
                }}
              >
                Авторизироваться
              </div>
            </div>
          </div>
        )}
        <Navbar nav={nav} setNav={setNav} />
        <AnimatePresence wait>
          <Routes>
            <Route
              path={routes.whereToStartMavie}
              element={<WhereToStartMavie />}
            />
            <Route
              path={routes.chooseTemplateMavie}
              element={<ChooseTemplateMavie />}
            />
            <Route
              path={routes.wantToJoinMavie}
              element={<WantToJoinMavie />}
            />
            <Route path={routes.mavieMain} element={<MavieMain />} />
            <Route path={routes.chooseTemplates} element={<ChooseTemplate />} />
            <Route path={routes.welcomePage} element={<WelcomPage />} />
            <Route
              path={routes.userTablePage}
              element={<UserTable nav={nav} />}
            />
            <Route path={routes.dataBase} element={<DataBase />} />
            <Route path={routes.dbWhereToStart} element={<DBWhereToStart />} />
            <Route path={routes.dbWantToJoin} element={<DBWantToJoin />} />
            <Route path={routes.dbPromotion} element={<DBPromotion />} />
            <Route path={routes.dbDocuments} element={<DBDocuments />} />
            <Route path={routes.dbResources} element={<DBResources />} />
            <Route path={routes.dbBirges} element={<DBBirges />} />
            <Route path={routes.dbAnalytics} element={<DBAnalytics />} />
          </Routes>
        </AnimatePresence>
      </Context.Provider>
    </div>
  );
}

export default App;
