import titleImg from "./title_img.png";
import template1 from "./1.png";
import template2 from "./2.png";
import template3 from "./3.png";
import template4 from "./4.png";
import drag from "./drag.png";
import { routes } from "../../routes";
import "./style.css";
import { useEffect, useState } from "react";
import { getBase64 } from "../../const";
import { useNavigate } from "react-router-dom";
export const ChooseTemplateContainer = () => {
  const Navigate = useNavigate();
  const [choosenTemplate, setChoosenTemplate] = useState();
  const addTemplate = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let myParam = urlParams.get("id");
    let sendObj = {};
    let postObj = new Object();
    if (choosenTemplate == 4) {
      let file = window.document.getElementById("fileImg").files[0];
      if (file == undefined) {
        alert("Вы не добавили изображение");
        return;
      }
      getBase64(file).then((data) => {
        postObj.imgBase64 = data;
        postObj.imgExtension = file.name.split(".").pop();
        sendObj.img = postObj;
        let title = window.document.getElementById("title").value;
        if (title == "") {
          alert("Вы не добавили заголовок");
          return;
        }
        let subtitle;
        if (choosenTemplate == 2 || choosenTemplate == 3) {
          subtitle = window.document.getElementById("subTitle").value;
          if (subtitle == "") {
            alert("Вы не добавили подзаголовок");
            return;
          }
          sendObj.subtitle = subtitle;
        }
        sendObj.title = title;
        sendObj.type = choosenTemplate;
        fetch(`https://cbt-version.ru/UltronAdminApi/PageType/?id=${myParam}`, {
          method: "POST",
          body: JSON.stringify(sendObj),
        }).then((response) => {
          if (response.status == 200) {
            Navigate(`${routes.dbWhereToStart}?id=${myParam}`);
          }
        });
      });
    } else {
      let title = window.document.getElementById("title").value;
      if (title == "") {
        alert("Вы не добавили заголовок");
        return;
      }
      let subtitle;
      if (choosenTemplate == 2 || choosenTemplate == 3) {
        subtitle = window.document.getElementById("subTitle").value;
        if (subtitle == "") {
          alert("Вы не добавили подзаголовок");
          return;
        }
        sendObj.subtitle = subtitle;
      }
      sendObj.title = title;
      sendObj.type = choosenTemplate;
      fetch(`https://cbt-version.ru/UltronAdminApi/PageType/?id=${myParam}`, {
        method: "POST",
        body: JSON.stringify(sendObj),
      }).then((response) => {
        if (response.status == 200) {
          Navigate(`${routes.dbWhereToStart}?id=${myParam}`);
        }
      });
    }
  };
  useEffect(() => {}, []);
  return (
    <div className="choosetemplatecontainer">
      <div className="title_bar">
        <img src={titleImg} alt="" />
        <h1>
          Выберите шаблон <br /> для раздела
        </h1>
      </div>
      <div className="choose__template_content">
        <div
          className="choose__template__em"
          onClick={() => {
            setChoosenTemplate(1);
          }}
          style={{ transform: choosenTemplate == 1 && "translateY(-25px)" }}
        >
          <img src={template1} alt="" />
        </div>
        <div
          className="choose__template__em"
          onClick={() => {
            setChoosenTemplate(2);
          }}
          style={{ transform: choosenTemplate == 2 && "translateY(-25px)" }}
        >
          <img src={template2} alt="" />
        </div>
        <div
          className="choose__template__em"
          onClick={() => {
            setChoosenTemplate(3);
          }}
          style={{ transform: choosenTemplate == 3 && "translateY(-25px)" }}
        >
          <img src={template3} alt="" />
        </div>
        <div
          className="choose__template__em"
          onClick={() => {
            setChoosenTemplate(4);
          }}
          style={{ transform: choosenTemplate == 4 && "translateY(-25px)" }}
        >
          <img src={template4} alt="" />
        </div>
        <div
          className="green__button"
          onClick={() => {
            addTemplate();
          }}
        >
          Далее
        </div>
      </div>
      <div className="grag__drop_ems">
        <div className="input_title">
          <p>Введите заголовок</p>
          <input type="text" id="title" />
        </div>
        <div
          className="input_title"
          style={{
            display: (choosenTemplate == 1 || choosenTemplate == 4) && "none",
          }}
        >
          <p>Введите подзаголовок</p>
          <input type="text" id="subTitle" />
        </div>
        <div
          className="input_img"
          style={{ display: choosenTemplate != 4 && "none" }}
        >
          <p>Установите изображение</p>
          <div>
            <img src={drag} alt="" />
            <input
              type="file"
              id="fileImg"
              style={{
                display: "none",
              }}
            />
            <p>ИЛИ</p>
            <label htmlFor="fileImg" className="green__button">
              Загрузить
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
