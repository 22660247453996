import "./style.css";
import chooseSectionImg1 from "../../img/chooseSectionImg1.png";
import chooseSectionImg2 from "../../img/chooseSectionImg2.png";
import chooseSectionImg3 from "../../img/chooseSectionImg3.png";
import chooseSectionImg4 from "../../img/chooseSectionImg4.png";
import outsideButtonsChange from "../../img/outsideButtonsChange.png";
import outsideButtonsAdd from "./1.svg";
import outsideButtonsPosition from "../../img/outsideButtonsPosition.png";
import outsideButtonsVisibility from "../../img/outsideButtonsVisibility.png";
import outsideButtonsDelete from "../../img/outsideButtonsDelete.png";
import arrowButtonBack from "../../img/arrowButtonBack.png";
import { DataBaseButtonSaveChanges } from "../../Components/DataBaseButtonSaveChanges";
import { DataBaseChoosePosition } from "../../Components/DataBaseChoosePosition";
import { DataBaseEnterTitle } from "../../Components/DataBaseEnterTitle";
import { DataBaseLoadImg } from "../../Components/DataBaseLoadImg";
import { DataBaseAddSection } from "../../Components/DataBaseAddSection";
import { json, Link } from "react-router-dom";
import dragNdropImg from "../../img/dragNdropImg.png";
import { routes } from "../../routes";
import mainRightLine from "../../img/main-right-line.png";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getBase64 } from "../../const";
import { Context } from "../Context";
export const MavieContainer = () => {
  const Navigate = useNavigate();
  const [elements, setElements] = useState([]);
  const [choosenElement, setChoosenElement] = useState(10000);
  const [choosenElementId, setChoosenElementId] = useState();
  const [showModalDelete, isShowModalDelete] = useState(false);
  const [showModalChange, isShowModalChange] = useState(false);
  const [showModalChangeElement, isShowModalChangeElement] = useState(false);
  const [errorPosition, setErrorPosition] = useState(false);
  const [pattern, isPattern] = useState();
  //ОБРАБАТЫВАЕМ ИЗОБРАЖЕНИЕ
  let admin = useContext(Context);
  //ДОБАВЛЕНИЕ ЭЛЕМЕНТА

  function addElement() {
    let postObj = new Object();
    let file = window.document.getElementById("fileImg").files[0];
    if (file == undefined) {
      alert("Вы не добавили изображение");
      return;
    }
    getBase64(file).then((data) => {
      postObj.imgBase64 = data;
      postObj.imgExtension = file.name.split(".").pop();
      let title = window.document.getElementById("title__value").value;
      if (title == "") {
        alert("Вы не добавили заголовок");
        return;
      }
      let position1 = window.document.getElementById("position__elem").value;
      console.log(postObj);
      fetch("https://cbt-version.ru/UltronAdminApi/Page_Mavie/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: title,
          img: postObj,
          position: position1,
        }),
      }).then((reponse) => {
        getElements();
        alert("Элемент успешно добавлен");
      });
    });
  }

  //УДАЛЕНИЕ ЭЛЕМЕНТА

  const deleteElement = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Page_Mavie/?id=${choosenElementId}`,
      { method: "DELETE" }
    ).then((response) => {
      if (response.status == 200) {
        alert("Элемент успешно удален");
        isShowModalDelete(false);
        getElements();
        setChoosenElement(10000);
      }
    });
  };

  //СМЕНА ПОЗИЦИИ

  const changePositionELement = () => {
    let newPosition = window.document.getElementById("newPosition").value;
    if (newPosition.length > 0) {
      setErrorPosition(false);
      let newPos = JSON.stringify({ newPosition: newPosition });
      fetch(
        `https://cbt-version.ru/UltronAdminApi/Page_Mavie/?id=${choosenElementId}`,
        {
          method: "PUT",
          body: newPos,
        }
      ).then((response) => {
        if (response.status == 200) {
          alert("Позиция успешно изменена");
          isShowModalChange(false);
          getElements();
        }
      });
    } else {
      setErrorPosition(true);
    }
  };

  //ВИДИМОСТЬ ЭЛЕМЕНТА

  const visibilityElement = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Page_Mavie/?id=${choosenElementId}`,
      { method: "PUT" }
    ).then((response) => {
      getElements();
      alert("Элемент скрыт");
    });
  };

  //ПОЛУЧЕНИЕ СПИСКА ЭЛЕМЕНТОВ

  const getElements = () => {
    fetch("https://cbt-version.ru/UltronAdminApi/Page_Mavie/")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setElements(data);
      });
  };

  //ИЗМЕНЕНИЕ ЭЛЕМЕНТА

  const changeElement = () => {
    let newTitle = window.document.getElementById("changeTitle").value;

    let postObj = new Object();
    let file = window.document.getElementById("changeImg").files[0];
    if (file) {
      getBase64(file).then((data) => {
        postObj.imgBase64 = data;
        postObj.imgExtension = file.name.split(".").pop();
        fetch(
          `https://cbt-version.ru/UltronAdminApi/Page_Mavie/?id=${choosenElementId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Accept: "application/json",
            },
            body: JSON.stringify({
              name: newTitle,
              img: postObj,
            }),
          }
        ).then((reponse) => {
          getElements();
          alert("Элемент успешно изменен");
          isShowModalChangeElement(false);
        });
      });
    } else {
      fetch(
        `https://cbt-version.ru/UltronAdminApi/Page_Mavie/?id=${choosenElementId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: newTitle,
          }),
        }
      ).then((reponse) => {
        getElements();
        alert("Элемент успешно изменен");
        isShowModalChangeElement(false);
      });
    }
  };

  useEffect(() => {
    getElements();
  }, []);
  return (
    <div className="database_container_main">
      {showModalDelete && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Вы действительно хотите удалить этот элемент?</p>
            <div>
              <button
                onClick={() => {
                  deleteElement();
                }}
              >
                Удалить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalDelete(false);
                }}
              >
                Оставить
              </button>
            </div>
          </div>
        </div>
      )}
      {showModalChange && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Введите новую позицию элемента</p>
            <input
              type="text"
              id="newPosition"
              style={{
                border: errorPosition && "2px solid red",
              }}
            />
            <div>
              <button
                onClick={() => {
                  changePositionELement();
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalChange(false);
                }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      {showModalChangeElement && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Укажите заголовок</p>
            <input type="text" id="changeTitle" />
            <p>Укажите новое изображение</p>
            <input type="file" id="changeImg" />
            <div>
              <button
                onClick={() => {
                  changeElement();
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalChangeElement(false);
                }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="database_container">
        <div className="database_title">
          Выберите раздел
          <img className="mainRightLine" src={mainRightLine} />
          <br />
          для редактирования
        </div>
        <div className="database_content">
          <div className="phone_page">
            <div className="phone_page_box_white_background">
              <div className="phone_page_box">
                <div className="phone_page_button_onMain">
                  <img className="arrowButtonBack" src={arrowButtonBack} />
                  <p className="phone_page_button_onMain_title text_transform">
                    на главную
                  </p>
                </div>
                <p className="phone_page_title">база знаний</p>
                <div className="phone_page_content">
                  {elements.map((elem, index) => {
                    return (
                      <div
                        key={index}
                        to={routes.dbWhereToStart}
                        className="phone_page_content_part"
                        onClick={() => {
                          setChoosenElement(index);
                          setChoosenElementId(elem.ID);
                          isPattern(elem.IS_PATTERN);
                        }}
                        style={{
                          flexDirection: index % 2 != 0 && "row-reverse",
                          border:
                            index == choosenElement && "2px solid #ab84fd",
                          opacity: elem.VISIBILITY == "0" && "0.5",
                        }}
                      >
                        <img
                          className="chooseSectionImg"
                          src={"UltronAdminApi/img/" + elem.IMG_SRC}
                        />
                        <div className="phone_page_content_part_number">
                          <p className="stepsNumber">{index + 1}</p>
                          <p className="phone_page_content_part_title text_transform">
                            {elem.NAME}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="phone_page_outside_buttons"
              style={{
                opacity: choosenElement == 10000 && "0.5",
                pointerEvents: choosenElement == 10000 && "none",
              }}
            >
              <div
                className="outside_buttons_box"
                onClick={() => {
                  pattern == "0"
                    ? Navigate(`/ChooseTemplateMavie?id=${choosenElementId}`)
                    : Navigate(
                        `${routes.whereToStartMavie}?id=${choosenElementId}`
                      );
                }}
              >
                <div className="outside_buttons_img">
                  <img className="outsideButtonsImg" src={outsideButtonsAdd} />
                </div>
                <p className="outside_buttons_title text_transform">
                  Редактировать
                </p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => {
                  isShowModalChangeElement(true);
                }}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsChange}
                  />
                </div>
                <p className="outside_buttons_title text_transform">изменить</p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => {
                  isShowModalChange(true);
                }}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsPosition}
                  />
                </div>
                <p className="outside_buttons_title text_transform">позиция</p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => {
                  visibilityElement();
                }}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsVisibility}
                  />
                </div>
                <p className="outside_buttons_title text_transform">
                  видимость
                </p>
              </div>
              {admin && (
                <div
                  className="outside_buttons_box"
                  onClick={() => {
                    isShowModalDelete(true);
                  }}
                >
                  <div className="outside_buttons_img">
                    <img
                      className="outsideButtonsImg"
                      src={outsideButtonsDelete}
                    />
                  </div>
                  <p className="outside_buttons_title text_transform">
                    удалить
                  </p>
                </div>
              )}
            </div>
          </div>
          <DataBaseAddSection />
          <div className="database_right_settings">
            <div className="right_settings_title_box">
              <p className="right_settings_title_title">
                Введите
                <br />
                заголовок
              </p>
              <input
                id="title__value"
                className="right_settings_title_textarea text_transform"
              ></input>
            </div>
            <div className="right_settings_image">
              <p className="right_settings_image_title">
                установите изображение
              </p>
              <div className="right_settings_image_dragNdrop">
                <img className="dragNdropImg" src={dragNdropImg} />
                <p className="right_settings_image_dragImg text_transform">
                  перетащите изображение...
                </p>
                <p className="right_settings_image_or text_transform">или</p>
                <input
                  type="file"
                  id="fileImg"
                  style={{
                    display: "none",
                  }}
                />

                <label
                  htmlFor="fileImg"
                  className="right_settings_image_load_button text_transform"
                >
                  загрузить
                </label>
              </div>
            </div>
            <div className="right_settings_position">
              <p className="right_settings_position_title text_transform">
                позиция
              </p>
              <input
                type="text"
                id="position__elem"
                className="right_settings_position_position_number text_transform"
              ></input>
            </div>
            <button
              className="right_settings_save_changes_button text_transform"
              onClick={() => {
                addElement();
              }}
            >
              сохранить изменения
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
