import "./style.css"
import dragNdropImg from "../../img/dragNdropImg.png"

export const DataBaseLoadImg = () =>{
    return(
        <div className="right_settings_image">
            <p className="right_settings_image_title">
                установите изображение
            </p>
            <div className="right_settings_image_dragNdrop">
                <img className="dragNdropImg" src={dragNdropImg}/>
                <p className="right_settings_image_dragImg text_transform">перетащите изображение...</p>
                <p className="right_settings_image_or text_transform">или</p>
                <button className="right_settings_image_load_button text_transform">загрузить</button>
            </div>
        </div>
    )
}