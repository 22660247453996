import "./style.css";
import chooseSectionImg1 from "../../img/chooseSectionImg16.png";
import chooseSectionImg2 from "../../img/chooseSectionImg17.png";
import chooseSectionImg3 from "../../img/chooseSectionImg18.png";
import chooseSectionImg4 from "../../img/chooseSectionImg19.png";
import outsideButtonsChange from "../../img/outsideButtonsChange.png";
import outsideButtonsAdd from "./1.svg";
import outsideButtonsPosition from "../../img/outsideButtonsPosition.png";
import outsideButtonsVisibility from "../../img/outsideButtonsVisibility.png";
import outsideButtonsDelete from "../../img/outsideButtonsDelete.png";
import arrowButtonBack from "../../img/arrowButtonBack.png";
import { DataBaseRightSettings } from "../../Components/DataBaseRightSettings";
import { DataBaseAddPageView } from "../../Components/DataBaseAddPageView";
import mainRightLine from "../../img/main-right-line.png";
import { useContext, useEffect, useState } from "react";
import { getBase64 } from "../../const";
import arrow from "./arrow.svg";
import dragNdropImg from "../../img/dragNdropImg.png";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../Context";
import { routes } from "../../routes";
export const WhereToStartMavieContainer = () => {
  const [pageInfo, setPageInfo] = useState();
  const [choosenElementId, setChoosenElementId] = useState();
  const [choosenElement, setChoosenElement] = useState(10000);
  const Navigate = useNavigate();
  let admin = useContext(Context);
  const [showModalDelete, isShowModalDelete] = useState(false);
  const [showModalChange, isShowModalChange] = useState(false);
  const [showModalChangeElement, isShowModalChangeElement] = useState(false);
  const [errorPosition, setErrorPosition] = useState(false);
  let urlParams = new URLSearchParams(window.location.search);
  let myParam = urlParams.get("id");
  function addElement() {
    let postObj = new Object();
    let file = window.document.getElementById("fileImg").files[0];
    if (file == undefined) {
      alert("Вы не добавили изображение");
      return;
    }
    getBase64(file).then((data) => {
      postObj.imgBase64 = data;
      postObj.imgExtension = file.name.split(".").pop();
      let title = window.document.getElementById("title__value").value;
      if (title == "") {
        alert("Вы не добавили заголовок");
        return;
      }
      let position1 = window.document.getElementById("position__elem").value;
      console.log(postObj);
      if (pageInfo && pageInfo.type.TYPE == "4") {
        let src1 = document.getElementById("src__value").value;
        fetch(
          `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${myParam}&type=preview`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Accept: "application/json",
            },
            body: JSON.stringify({
              title: title,
              img: postObj,
              position: position1,
              src: src1,
            }),
          }
        ).then((reponse) => {
          getElements();
          alert("Элемент успешно добавлен");
        });
      } else {
        fetch(
          `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${myParam}&type=preview`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Accept: "application/json",
            },
            body: JSON.stringify({
              title: title,
              img: postObj,
              position: position1,
            }),
          }
        ).then((reponse) => {
          getElements();
          alert("Элемент успешно добавлен");
        });
      }
    });
  }

  //УДАЛЕНИЕ ЭЛЕМЕНТА

  const deleteElement = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${choosenElementId}&type=preview&idParent=${myParam}`,
      { method: "DELETE" }
    ).then((response) => {
      if (response.status == 200) {
        alert("Элемент успешно удален");
        isShowModalDelete(false);
        getElements();
        setChoosenElement(10000);
      }
    });
  };

  //СМЕНА ПОЗИЦИИ

  const changePositionELement = () => {
    let newPosition = window.document.getElementById("newPosition").value;
    if (newPosition.length > 0) {
      setErrorPosition(false);
      let newPos = JSON.stringify({ newPosition: newPosition });
      fetch(
        `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${choosenElementId}&type=preview&idParent=${myParam}`,
        {
          method: "PUT",
          body: newPos,
        }
      ).then((response) => {
        if (response.status == 200) {
          alert("Позиция успешно изменена");
          isShowModalChange(false);
          getElements();
        }
      });
    } else {
      setErrorPosition(true);
    }
  };

  //ВИДИМОСТЬ ЭЛЕМЕНТА

  const visibilityElement = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${choosenElementId}&type=preview&idParent=${myParam}`,
      { method: "PUT" }
    ).then((response) => {
      getElements();
      alert("Элемент скрыт");
    });
  };

  //ПОЛУЧЕНИЕ СПИСКА ЭЛЕМЕНТОВ

  const getElements = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${myParam}&type=preview`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPageInfo(data);
      });
  };

  //ИЗМЕНЕНИЕ ЭЛЕМЕНТА

  const changeElement = () => {
    let newTitle = window.document.getElementById("changeTitle").value;

    let postObj = new Object();
    let file = window.document.getElementById("changeImg").files[0];
    if (file) {
      getBase64(file).then((data) => {
        postObj.imgBase64 = data;
        postObj.imgExtension = file.name.split(".").pop();
        fetch(
          `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${choosenElementId}&type=preview&idParent=${myParam}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Accept: "application/json",
            },
            body: JSON.stringify({
              title: newTitle,
              img: postObj,
            }),
          }
        ).then((response) => {
          getElements();
          alert("Элемент успешно изменен");
          isShowModalChangeElement(false);
        });
      });
    } else {
      fetch(
        `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${choosenElementId}&type=preview&idParent=${myParam}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            title: newTitle,
          }),
        }
      ).then((reponse) => {
        getElements();
        alert("Элемент успешно изменен");
        isShowModalChangeElement(false);
      });
    }
  };
  console.log(pageInfo);
  useEffect(() => {
    getElements();
  }, []);
  return (
    <div className="dbWhere_to_start_container_main">
      {showModalDelete && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Вы действительно хотите удалить этот элемент?</p>
            <div>
              <button
                onClick={() => {
                  deleteElement();
                }}
              >
                Удалить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalDelete(false);
                }}
              >
                Оставить
              </button>
            </div>
          </div>
        </div>
      )}
      {showModalChange && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Введите новую позицию элемента</p>
            <input
              type="text"
              id="newPosition"
              style={{
                border: errorPosition && "2px solid red",
              }}
            />
            <div>
              <button
                onClick={() => {
                  changePositionELement();
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalChange(false);
                }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      {showModalChangeElement && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Укажите заголовок</p>
            <input type="text" id="changeTitle" />
            <p>Укажите новое изображение</p>
            <input type="file" id="changeImg" />
            <div>
              <button
                onClick={() => {
                  changeElement();
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalChangeElement(false);
                }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="dbWhere_to_start_container">
        <div className="database_title">
          Вы редактируете раздел <br />"
          {pageInfo && pageInfo.type && pageInfo.type.TITLE}"
          <img className="mainRightLine" src={mainRightLine} />
        </div>
        <div className="database_content">
          <div className="phone_page">
            <div className="phone_page_box_white_background">
              <div className="phone_page_box">
                <div className="phone_page_button_onMain">
                  <img className="arrowButtonBack" src={arrowButtonBack} />
                  <p className="phone_page_button_onMain_title text_transform">
                    база знаний
                  </p>
                </div>
                <p className="phone_page_title lower_margin">
                  {pageInfo && pageInfo.type && pageInfo.type.TITLE}
                </p>
                {pageInfo &&
                  pageInfo.type &&
                  pageInfo.type.SUBTITLE != null && (
                    <p className="phone_page_undertitle text_transform">
                      <b className="text_transform2">
                        {pageInfo.type.SUBTITLE}
                      </b>
                    </p>
                  )}

                <div
                  className="phone_page_content"
                  style={
                    pageInfo && pageInfo.type && pageInfo.type.TYPE == "4"
                      ? {
                          flexWrap: "wrap",
                          flexDirection: "unset",
                          alignItems: "unset",
                        }
                      : {}
                  }
                >
                  {pageInfo &&
                    pageInfo.cards.length > 0 &&
                    pageInfo.cards.map((elem, index) => {
                      if (pageInfo && pageInfo.type.TYPE == "2") {
                        return (
                          <div
                            className="phone_page_content_part"
                            key={index}
                            onClick={() => {
                              setChoosenElementId(elem.ID);
                              setChoosenElement(index);
                            }}
                            style={{
                              opacity: elem.VISIBILITY == "0" && "0.5",
                              border:
                                choosenElementId == elem.ID &&
                                "3px solid #ab84fd",
                              borderRadius:
                                choosenElementId == elem.ID && "0.7vw",
                            }}
                          >
                            <img
                              className="chooseSectionImgDocs"
                              src={"UltronAdminApi/img/" + elem.IMG_SRC_PREVIEW}
                            />
                            <div className="phone_page_content_part_number_start">
                              <p className="phone_page_content_part_title_start text_transform">
                                {elem.TITLE_PREVIEW}
                              </p>
                              <button className="add_section_button_placeholder text_transform">
                                смотреть
                              </button>
                            </div>
                          </div>
                        );
                      }
                      if (pageInfo && pageInfo.type.TYPE == "1") {
                        return (
                          <div
                            key={index}
                            className="phone_page_content_part"
                            style={{
                              flexDirection: index % 2 != 0 && "row-reverse",

                              opacity: elem.VISIBILITY == "0" && "0.5",
                              border:
                                choosenElementId == elem.ID &&
                                "3px solid #ab84fd",
                              borderRadius:
                                choosenElementId == elem.ID && "0.7vw",
                            }}
                            onClick={() => {
                              setChoosenElementId(elem.ID);
                              setChoosenElement(index);
                            }}
                          >
                            <img
                              className="chooseSectionImg"
                              src={"UltronAdminApi/img/" + elem.IMG_SRC_PREVIEW}
                            />
                            <div className="phone_page_content_part_number">
                              <p className="stepsNumber">{index + 1}</p>
                              <p className="phone_page_content_part_title text_transform">
                                {elem.TITLE_PREVIEW}
                              </p>
                            </div>
                          </div>
                        );
                      }
                      if (pageInfo.type.TYPE == "3") {
                        return (
                          <div
                            className="pres_links"
                            onClick={() => {
                              setChoosenElementId(elem.ID);
                              setChoosenElement(index);
                            }}
                            style={{
                              opacity: elem.VISIBILITY == "0" && "0.5",
                              border:
                                choosenElementId == elem.ID &&
                                "3px solid #ab84fd",
                              borderRadius:
                                choosenElementId == elem.ID && "0.7vw",
                            }}
                          >
                            <img
                              src={"UltronAdminApi/img/" + elem.IMG_SRC_PREVIEW}
                              alt=""
                              className="img_title"
                            />
                            <p>{elem.TITLE_PREVIEW}</p>
                            <div className="arrow">
                              <img src={arrow} alt="" />
                            </div>
                          </div>
                        );
                      }
                      if (pageInfo.type.TYPE == "4") {
                        return (
                          <div
                            style={{
                              opacity: elem.VISIBILITY == "0" && "0.5",
                              border:
                                choosenElementId == elem.ID &&
                                "3px solid #ab84fd",
                              borderRadius:
                                choosenElementId == elem.ID && "0.7vw",
                            }}
                            className="add_coin_settings"
                            onClick={() => {
                              setChoosenElementId(elem.ID);
                              setChoosenElement(index);
                            }}
                          >
                            <p className="add_coin_settings_title text_transform">
                              {elem.TITLE_PREVIEW}
                            </p>
                            <div className="add_coin_settings_img_box">
                              <img
                                src={
                                  "UltronAdminApi/img/" + elem.IMG_SRC_PREVIEW
                                }
                                className="add_coin_settings_img"
                              />
                            </div>
                            <a
                              href={elem.SRC}
                              target="_blank"
                              className="add_coin_settings_button text_transform"
                            >
                              смотреть
                            </a>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
            <div
              className="phone_page_outside_buttons"
              style={{
                opacity: choosenElement == 10000 && "0.5",
                pointerEvents: choosenElement == 10000 && "none",
                height:
                  pageInfo &&
                  pageInfo.type &&
                  pageInfo.type.TYPE == "4" &&
                  "16.5vw",
              }}
            >
              <div
                className="outside_buttons_box"
                style={{
                  display:
                    pageInfo &&
                    pageInfo.type &&
                    pageInfo.type.TYPE == "4" &&
                    "none",
                }}
                onClick={() => {
                  Navigate(routes.wantToJoinMavie + "?key=" + choosenElementId);
                }}
              >
                <div className="outside_buttons_img">
                  <img className="outsideButtonsImg" src={outsideButtonsAdd} />
                </div>
                <p className="outside_buttons_title text_transform">
                  Редактировать
                </p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => {
                  isShowModalChangeElement(true);
                }}
                style={{
                  display:
                    pageInfo &&
                    pageInfo.type &&
                    pageInfo.type.TYPE == "4" &&
                    "none",
                }}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsChange}
                  />
                </div>
                <p className="outside_buttons_title text_transform">изменить</p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => {
                  isShowModalChange(true);
                }}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsPosition}
                  />
                </div>
                <p className="outside_buttons_title text_transform">позиция</p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => {
                  visibilityElement();
                }}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsVisibility}
                  />
                </div>
                <p className="outside_buttons_title text_transform">
                  видимость
                </p>
              </div>
              {admin && (
                <div
                  className="outside_buttons_box"
                  onClick={() => {
                    isShowModalDelete(true);
                  }}
                >
                  <div className="outside_buttons_img">
                    <img
                      className="outsideButtonsImg"
                      src={outsideButtonsDelete}
                    />
                  </div>
                  <p className="outside_buttons_title text_transform">
                    удалить
                  </p>
                </div>
              )}
            </div>
          </div>
          <DataBaseAddPageView />
          <div
            className="database_right_settings"
            style={{ marginTop: "-8vw", height: "96vh" }}
          >
            <div className="right_settings_title_box">
              <p className="right_settings_title_title">
                Введите
                <br />
                заголовок
              </p>
              <input
                id="title__value"
                className="right_settings_title_textarea text_transform"
              ></input>
            </div>
            {pageInfo && pageInfo.type && pageInfo.type.TYPE == "4" && (
              <div className="right_settings_title_box">
                <p className="right_settings_title_title">текст ссылки</p>
                <input
                  id="src__value"
                  className="right_settings_title_textarea text_transform"
                ></input>
              </div>
            )}
            <div className="right_settings_image">
              <p className="right_settings_image_title">
                установите изображение
              </p>
              <div className="right_settings_image_dragNdrop">
                <img className="dragNdropImg" src={dragNdropImg} />
                <p className="right_settings_image_dragImg text_transform">
                  перетащите изображение...
                </p>
                <p className="right_settings_image_or text_transform">или</p>
                <input
                  type="file"
                  id="fileImg"
                  style={{
                    display: "none",
                  }}
                />

                <label
                  htmlFor="fileImg"
                  className="right_settings_image_load_button text_transform"
                >
                  загрузить
                </label>
              </div>
            </div>
            <div className="right_settings_position">
              <p className="right_settings_position_title text_transform">
                позиция
              </p>
              <input
                type="text"
                id="position__elem"
                className="right_settings_position_position_number text_transform"
              ></input>
            </div>
            <button
              className="right_settings_save_changes_button text_transform"
              onClick={() => {
                addElement();
              }}
            >
              сохранить изменения
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
