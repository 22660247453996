import "./style.css"
import { DataBaseButtonSaveChanges } from "../../Components/DataBaseButtonSaveChanges"
import { DataBaseChoosePosition } from "../../Components/DataBaseChoosePosition"
import { DataBaseEnterName } from "../../Components/DataBaseEnterName"
import { DataBaseLoadImg } from "../../Components/DataBaseLoadImg"

export const DataBaseRightSettings = () =>{
    return(
        <div className="database_right_settings">
            <DataBaseEnterName/>
            <DataBaseLoadImg/>
            <DataBaseChoosePosition/>
            <DataBaseButtonSaveChanges/>
        </div>
    )
}