import "./style.css";

export const DataBaseAddCoinLink = () => {
  return (
    <div className="data_base_add_coin_link_box">
      <p className="data_base_add_coin_link_title">
        Введите текст <br /> ссылки
      </p>
      <textarea
        className="data_base_add_coin_link_textarea text_transform"
        defaultValue="Введенный текст"
      ></textarea>
    </div>
  );
};
