import "./style.css"
import addSectionImg from "../../img/addSectionImg.png"

export const DataBaseAddSection = () =>{
    return(
        <div className="add_section">
            <p className="add_section_title text_transform">добавьте раздел</p>
            <div className="add_section_settings">
                <div className="add_section_img_placeholder">
                    <img className="addSectionImg" src={addSectionImg}/>
                </div>
                <p className="stepsNumber">1</p>
                <p className="add_section_title_placeholder text_transform">название раздела</p>
            </div>
        </div>
    )
}