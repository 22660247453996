import "./style.css"

export const DataBaseEnterName = () =>{
    return(
        <div className="right_settings_title_box">
            <p className="right_settings_title_title">
                Введите <br/> название
            </p>
            <textarea className="right_settings_name_textarea text_transform">Введенный текст</textarea>
        </div>
    )
}