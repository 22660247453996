import "./style.css"
import chooseSectionImg1 from "../../img/chooseSectionImg9.png"
import chooseSectionImg2 from "../../img/chooseSectionImg10.png"
import chooseSectionImg3 from "../../img/chooseSectionImg11.png"
import chooseSectionImg4 from "../../img/chooseSectionImg12.png"
import outsideButtonsChange from "../../img/outsideButtonsChange.png"
import outsideButtonsPosition from "../../img/outsideButtonsPosition.png"
import outsideButtonsVisibility from "../../img/outsideButtonsVisibility.png"
import outsideButtonsDelete from "../../img/outsideButtonsDelete.png"
import arrowButtonBack from "../../img/arrowButtonBack.png"
import { DataBaseRightSettings } from "../../Components/DataBaseRightSettings"
import { DataBaseAddPage } from "../../Components/DataBaseAddPage"
import { Link } from "react-router-dom"
import mainRightLine from "../../img/main-right-line.png"

export const DBPromotionContainer = () =>{
    return(
        <div className="dbPromotion_container_main">
            <div className="dbPromotion_container">
                <div className="database_title">
                    Вы редактируете раздел <br/>
                    "для продвижения"
                    <img className="mainRightLine" src={mainRightLine}/>
                </div>
                <div className="database_content">
                    <div className="phone_page">
                        <div className="phone_page_box_white_background">
                        <div className="phone_page_box">
                            <div className="phone_page_button_onMain">
                                <img className="arrowButtonBack" src={arrowButtonBack}/>
                                <p className="phone_page_button_onMain_title text_transform">база знаний</p>
                            </div>
                            <p className="phone_page_title">продвижение</p>
                            <div className="phone_page_content">
                                <div className="phone_page_content_part">
                                    <div className="phone_page_content_part_hover">
                                        <div className="phone_page_content_part_hover_content text_transform">редактировать</div>
                                    </div>
                                    <img className="chooseSectionImg" src={chooseSectionImg1}/>
                                    <div className="phone_page_content_part_number">
                                        <p className="stepsNumber">1</p>
                                        <p className="phone_page_content_part_title text_transform">Как работает автоматизация</p>
                                    </div>
                                </div>
                                <div className="phone_page_content_part" style={{flexDirection: "row-reverse"}}>
                                    <div className="phone_page_content_part_hover">
                                        <div className="phone_page_content_part_hover_content text_transform">редактировать</div>
                                    </div>
                                    <img className="chooseSectionImg lower_padding" src={chooseSectionImg2}/>
                                    <div className="phone_page_content_part_number">
                                        <p className="stepsNumber">2</p>
                                        <p className="phone_page_content_part_title text_transform">Автоматизация с чего начать</p>
                                    </div>
                                </div>
                                <div className="phone_page_content_part">
                                    <div className="phone_page_content_part_hover">
                                        <div className="phone_page_content_part_hover_content text_transform">редактировать</div>
                                    </div>
                                    <img className="chooseSectionImg" src={chooseSectionImg3}/>
                                    <div className="phone_page_content_part_number">
                                        <p className="stepsNumber">3</p>
                                        <p className="phone_page_content_part_title text_transform">Продающие посты</p>
                                    </div>
                                </div>
                                <div className="phone_page_content_part" style={{flexDirection: "row-reverse"}}>
                                    <div className="phone_page_content_part_hover">
                                        <div className="phone_page_content_part_hover_content text_transform">редактировать</div>
                                    </div>
                                    <img className="chooseSectionImg" src={chooseSectionImg4}/>
                                    <div className="phone_page_content_part_number">
                                        <p className="stepsNumber">4</p>
                                        <p className="phone_page_content_part_title text_transform">Маркетинг Mavie</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="phone_page_outside_buttons">
                            <div className="outside_buttons_box">
                                <div className="outside_buttons_img">
                                    <img className="outsideButtonsImg" src={outsideButtonsChange}/>
                                </div>
                                <p className="outside_buttons_title text_transform">изменить</p>
                            </div>
                            <div className="outside_buttons_box">
                                <div className="outside_buttons_img">
                                    <img className="outsideButtonsImg" src={outsideButtonsPosition}/>
                                </div>
                                <p className="outside_buttons_title text_transform">позиция</p>
                            </div>
                            <div className="outside_buttons_box">
                                <div className="outside_buttons_img">
                                    <img className="outsideButtonsImg" src={outsideButtonsVisibility}/>
                                </div>
                                <p className="outside_buttons_title text_transform">видимость</p>
                            </div>
                            <div className="outside_buttons_box">
                                <div className="outside_buttons_img">
                                    <img className="outsideButtonsImg" src={outsideButtonsDelete}/>
                                </div>
                                <p className="outside_buttons_title text_transform">удалить</p>
                            </div>
                        </div>
                    </div>
                    <DataBaseAddPage/>
                    <DataBaseRightSettings/>
                </div>
            </div>
        </div>
    )
}