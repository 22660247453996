import "./style.css"
import outsideButtonsChange from "../../img/outsideButtonsChange.png"
import outsideButtonsPosition from "../../img/outsideButtonsPosition.png"
import outsideButtonsVisibility from "../../img/outsideButtonsVisibility.png"
import outsideButtonsDelete from "../../img/outsideButtonsDelete.png"
import arrowButtonBack from "../../img/arrowButtonBack.png"
import { DataBaseRightSettingsCoins } from "../../Components/DataBaseRightSettingsCoins"
import { DataBaseAddCoin } from "../../Components/DataBaseAddCoin"
import addCoin from "../../img/addCoin.png"
import chooseCoin1 from "../../img/chooseCoin1.png"
import chooseCoin2 from "../../img/chooseCoin2.png"
import chooseCoin3 from "../../img/chooseCoin3.png"
import chooseCoin4 from "../../img/chooseCoin4.png"
import chooseCoin5 from "../../img/chooseCoin5.png"
import chooseCoin6 from "../../img/chooseCoin6.png"
import coinsTitleImg from "../../img/coinsTitleImg1.png"
import mainRightLine from "../../img/main-right-line.png"
export const DBResourcesContainer = () =>{
    return(
        <div className="dbResources_container_main">
            <div className="dbResources_container">
                <div className="database_title">
                    Вы редактируете раздел <br/>
                    "ресурсы ultron"
                    <img className="mainRightLine" src={mainRightLine}/>
                </div>
                <div className="database_content">
                    <div className="phone_page">
                        <div className="phone_page_box_white_background">
                        <div className="phone_page_box">
                            <div className="phone_page_button_onMain">
                                <img className="arrowButtonBack" src={arrowButtonBack}/>
                                <p className="phone_page_button_onMain_title text_transform">база знаний</p>
                            </div>
                            <p className="phone_page_title">ресурсы ultron</p>
                            <div className="coins_title_img_box">
                                <img className="coinsTitleImg1" src={coinsTitleImg}/>
                                <p className="coins_title_text text_transform">
                                    Команда проекта <br/><span className="text_transform3">более 40 человек</span>
                                </p>
                            </div>
                            <div className="phone_page_content">
                                <div className="row_coins">
                                    <div className="add_coin_settings">
                                        <p className="add_coin_settings_title text_transform">Разработка мета вселенной</p>
                                        <div className="add_coin_settings_img_box">
                                            <img src={chooseCoin1} className="add_coin_settings_img"/>
                                        </div>
                                        <button className="add_coin_settings_button text_transform">смотреть</button>
                                    </div>
                                    <div className="add_coin_settings">
                                        <p className="add_coin_settings_title text_transform">Сайт блокчейна Ultron
</p>
                                        <div className="add_coin_settings_img_box">
                                            <img src={chooseCoin2} className="add_coin_settings_img"/>
                                        </div>
                                        <button className="add_coin_settings_button text_transform">смотреть</button>
                                    </div>
                                    <div className="add_coin_settings">
                                        <p className="add_coin_settings_title text_transform">GitHub
код блокчейна</p>
                                        <div className="add_coin_settings_img_box">
                                            <img src={chooseCoin3} className="add_coin_settings_img"/>
                                        </div>
                                        <button className="add_coin_settings_button text_transform">смотреть</button>
                                    </div>
                                </div>
                                <div className="row_coins">
                                    <div className="add_coin_settings">
                                        <p className="add_coin_settings_title text_transform">Биржа UltronSwap</p>
                                        <div className="add_coin_settings_img_box">
                                            <img src={chooseCoin4} className="add_coin_settings_img"/>
                                        </div>
                                        <button className="add_coin_settings_button text_transform">смотреть</button>
                                    </div>
                                    <div className="add_coin_settings">
                                        <p className="add_coin_settings_title text_transform">Сайт маркетинга</p>
                                        <div className="add_coin_settings_img_box">
                                            <img src={chooseCoin5} className="add_coin_settings_img"/>
                                        </div>
                                        <button className="add_coin_settings_button text_transform">смотреть</button>
                                    </div>
                                    <div className="add_coin_settings">
                                        <p className="add_coin_settings_title text_transform">Explorer ULX</p>
                                        <div className="add_coin_settings_img_box">
                                            <img src={chooseCoin6} className="add_coin_settings_img"/>
                                        </div>
                                        <button className="add_coin_settings_button text_transform">смотреть</button>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                        </div>
                        <div className="phone_page_outside_buttons">
                            <div className="outside_buttons_box">
                                <div className="outside_buttons_img">
                                    <img className="outsideButtonsImg" src={outsideButtonsChange}/>
                                </div>
                                <p className="outside_buttons_title text_transform">изменить</p>
                            </div>
                            <div className="outside_buttons_box">
                                <div className="outside_buttons_img">
                                    <img className="outsideButtonsImg" src={outsideButtonsPosition}/>
                                </div>
                                <p className="outside_buttons_title text_transform">позиция</p>
                            </div>
                            <div className="outside_buttons_box">
                                <div className="outside_buttons_img">
                                    <img className="outsideButtonsImg" src={outsideButtonsVisibility}/>
                                </div>
                                <p className="outside_buttons_title text_transform">видимость</p>
                            </div>
                            <div className="outside_buttons_box">
                                <div className="outside_buttons_img">
                                    <img className="outsideButtonsImg" src={outsideButtonsDelete}/>
                                </div>
                                <p className="outside_buttons_title text_transform">удалить</p>
                            </div>
                        </div>
                    </div>
                    <DataBaseAddCoin/>
                    <DataBaseRightSettingsCoins/>
                </div>
            </div>
        </div>
    )
}