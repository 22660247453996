export const routes = {
  chooseTemplates: "/ChooseTemplate",
  welcomePage: "/",
  userTablePage: "/UserTablePage",
  dataBase: "/DataBase",
  dbWhereToStart: "/WhereToStart",
  dbWantToJoin: "/WantToJoin",
  dbPromotion: "/Promotion",
  dbDocuments: "/Documents",
  dbResources: "/Resources",
  dbBirges: "/Birges",
  dbAnalytics: "/Analytics",
  mavieMain: "/MavieMain",
  chooseTemplateMavie: "/ChooseTemplateMavie",
  whereToStartMavie: "/WhereToStartMavie",
  wantToJoinMavie: "/WantToJoinMavie",
};
