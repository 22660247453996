import "../WelcomePageContainer/style.css";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import structure_img from "../../img/header-structure.png";
import left_line from "../../img/main-left-line.png";
import right_line from "../../img/main-right-line.png";
import central from "../../img/main-central-sphere.png";
import base from "../../img/main-base.png";
import docs from "../../img/main-docs.png";
import profile from "../../img/main-profile.png";
import id_btn from "../../img/main-copy-btn.png";
import { useContext, useEffect } from "react";
import ult from "./ult.png";
import ultron from "./1.svg";
import mavie from "./2.svg";
import { Context } from "../Context";
export const WelcomePageContainer = () => {
  let admin = useContext(Context);
  return (
    <div className="wp_main">
      <div className="mic">
        <img src={ult} alt="" />
        <div className="mic-shadow"></div>
      </div>
      <div className="wp_header">
        <div className="wp_header_sections">
          <p>системные разделы</p>
          <Link className="wp_header_sections_link" to={routes.dataBase}>
            ULTRON
          </Link>
          <Link className="wp_header_sections_link" to={routes.mavieMain}>
            MAVIE
          </Link>
          <Link className="wp_header_sections_link" to={routes.dbDocuments}>
            документы
          </Link>
        </div>
        {admin && (
          <div className="wp_header_structure">
            <Link
              className="wp_header_structure_link"
              to={routes.userTablePage}
            >
              <p>структура</p>
              <img src={structure_img} alt="" />
            </Link>
          </div>
        )}
      </div>
      <div className="wp_main_in">
        <div className="wp_main_in_welcome">
          <img src={left_line} alt="" />
          <p>добро пожаловать!</p>
          <img src={right_line} alt="" />
        </div>
        <div className="wp_main_in_center">
          <div
            className="wp_main_in_center_left1"
            style={{
              display: "flex",
              flexDirection: "column",
              background: "none",
              boxShadow: "none",
            }}
          >
            <Link to={routes.dataBase} className="wp_main_in_center_left_link">
              <img src={ultron} alt="" />
            </Link>
            <Link to={routes.mavieMain} className="wp_main_in_center_left_link">
              <img src={mavie} alt="" />
            </Link>
          </div>
          <img src={central} alt="" className="wp_main_in_center_sphere" />
          <div className="wp_main_in_center_left">
            <Link
              className="wp_main_in_center_left_link"
              to={routes.dbDocuments}
            >
              <img src={docs} alt="" className="wp_docs" />
              <p>документы</p>
            </Link>
          </div>
        </div>
        <div className="wp_main_in_bottom">
          <div className="wp_main_in_bottom_left">
            <p>ваш персональный id</p>
          </div>
          <div className="wp_main_in_bottom_right">
            <div className="wp_main_in_bottom_right_id">
              <p>id</p>
              <p>85</p>
              <button className="wp_copy_btn">
                <img src={id_btn} alt="" />
              </button>
            </div>
            <p className="wp_bottom_text">
              назовите этот код человеку <br /> которого хотите пригласить
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
