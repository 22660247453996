import "./style.css"
import { DataBaseButtonSaveChanges } from "../../Components/DataBaseButtonSaveChanges"
import { DataBaseChoosePosition } from "../../Components/DataBaseChoosePosition"
import { DataBaseEnterName } from "../../Components/DataBaseEnterName"
import { DataBaseLoadImg } from "../../Components/DataBaseLoadImg"
import { DataBaseAddCoinLink } from "../DataBaseAddCoinLink"
export const DataBaseRightSettingsCoins = () =>{
    return(
        <div className="database_right_settings_coins">
            <DataBaseAddCoinLink/>
            <DataBaseEnterName/>
            <DataBaseLoadImg/>
            <DataBaseChoosePosition/>
            <DataBaseButtonSaveChanges/>
        </div>
    )
}