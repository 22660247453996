import logo from "../../img/navbar-logo.png";
import navbar_main from "../../img/navbar-mainpage.png";
import { routes } from "../../routes";
import "../Navbar/style.css";
import { Link, useNavigate } from "react-router-dom";
import structure from "../../img/navbar-structure.png";
import analytics from "../../img/navbar-analytics.png";
import baza from "../../img/navbar-baza.png";
import begin from "../../img/navbar-begin.png";
import birzha from "../../img/navbar-birzha.png";
import docs from "../../img/navbar-docs.png";
import enter from "../../img/navbar-enter.png";
import home from "../../img/navbar-home.png";
import prod from "../../img/navbar-prod.png";
import res from "../../img/navbar-resourses.png";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Context } from "../../Containers/Context";
export const Navbar = ({ nav, setNav }) => {
  const [search, setSearch] = useState("");
  const [arr, setArr] = useState();
  const Navigate = useNavigate();
  function getResults() {
    if (search != "") {
      fetch("https://cbt-version.ru/UltronAdminApi/Search/?query=" + search)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setArr(data);
        });
    } else {
      setArr([]);
    }
  }
  function goTo(type, id) {
    if (type == "DOCUMENTS") {
      Navigate(routes.dbDocuments);
    }

    if (type == "PAGES_MAVIE") {
      Navigate("/WhereToStartMavie?id=" + id);
    }
    if (type == "PAGES") {
      Navigate("/WhereToStart?id=" + id);
    }
    if (type == "CARDS_MAVIE") {
      Navigate("/WantToJoinMavie?key=" + id);
    }
    if (type == "CARDS") {
      Navigate("/WantToJoin?key=" + id);
    }
  }
  useEffect(() => {
    getResults();
  }, [search]);

  let admin = useContext(Context);

  return (
    <div
      className="navbar-mainpage"
      style={{
        width: nav && "4vw",
      }}
    >
      <img src={logo} alt="logo-img" className="logo-img" />
      <div>
        {routes.userTablePage ? (
          <ul className="spisok">
            <li className="move">
              <img src={home} alt="" />
              <Link to={routes.welcomePage} className="spisok_link">
                <p>на главный</p>
              </Link>
            </li>
            <li className="move" style={{ flexDirection: "column" }}>
              <Link
                to={routes.dataBase}
                className="block spisok_link"
                style={{ textDecoration: "none" }}
              >
                <img src={baza} alt="" />
                <p>база Ultron</p>
              </Link>
            </li>
            <li className="move">
              <img src={structure} alt="" />
              <Link className="spisok_link" to={routes.mavieMain}>
                <p>База Mavie</p>
              </Link>
            </li>
            <li className="move">
              <img src={docs} alt="" />
              <Link className="spisok_link" to={routes.dbDocuments}>
                <p>документы</p>
              </Link>
            </li>
            {admin && (
              <li className="move">
                <img src={structure} alt="" />
                <Link className="spisok_link" to={routes.userTablePage}>
                  <p>структура</p>
                </Link>
              </li>
            )}
          </ul>
        ) : (
          <img
            src={navbar_main}
            alt="navbar-img"
            className="choose-template-img"
          />
        )}
      </div>
      <div className="search">
        <h4>Поиск</h4>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="results">
          {arr &&
            arr.length > 0 &&
            arr.map((em, index) => {
              return (
                <div key={index} className="result__em">
                  <p>{em.TITLE} </p>
                  <div
                    onClick={() => goTo(em.TYPE, em.ID)}
                    style={{ cursor: "pointer" }}
                  >
                    Перейти
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
