import "./style.css";
import chooseSectionImg1 from "../../img/chooseSectionImg13.png";
import chooseSectionImg2 from "../../img/chooseSectionImg14.png";
import chooseSectionImg3 from "../../img/chooseSectionImg15.png";
import outsideButtonsChange from "../../img/outsideButtonsChange.png";
import outsideButtonsPosition from "../../img/outsideButtonsPosition.png";
import outsideButtonsVisibility from "../../img/outsideButtonsVisibility.png";
import outsideButtonsDelete from "../../img/outsideButtonsDelete.png";
import arrowButtonBack from "../../img/arrowButtonBack.png";
import { DataBaseRightSettings } from "../../Components/DataBaseRightSettings";
import { DataBaseAddPageView } from "../../Components/DataBaseAddPageView";
import { Link } from "react-router-dom";
import mainRightLine from "../../img/main-right-line.png";
import { useState } from "react";
import { useEffect } from "react";
import dragNdropImg from "../../img/dragNdropImg.png";
import { getBase64 } from "../../const";
export const DBDocumentsContainer = () => {
  const [arrData, setArrData] = useState();
  const [choosenElement, setChoosenElement] = useState(10000);
  const [showModalDelete, isShowModalDelete] = useState(false);
  const [showModalChangeElement, isShowModalChangeElement] = useState(false);
  const [showModalChange, isShowModalChange] = useState(false);
  const [errorPosition, setErrorPosition] = useState(false);
  const [arrLength, setArrLength] = useState();
  let urlParams = new URLSearchParams(window.location.search);
  let myParam = urlParams.get("key");
  console.log(arrData);
  const getElements = () => {
    fetch("https://cbt-version.ru/UltronAdminApi/Document/")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setArrData(data);
        setArrLength(data.length);
      });
  };

  const changePositionELement = () => {
    let newPosition = window.document.getElementById("newPosition").value;
    if (newPosition.length > 0 && Number(newPosition) <= arrLength) {
      setErrorPosition(false);
      let newPos = JSON.stringify({ newPosition: newPosition });
      fetch(
        `https://cbt-version.ru/UltronAdminApi/Document/?id=${choosenElement}`,
        {
          method: "PUT",
          body: newPos,
        }
      ).then((response) => {
        if (response.status == 200) {
          alert("Позиция успешно изменена");
          isShowModalChange(false);
          getElements();
        }
      });
    } else {
      setErrorPosition(true);
    }
  };

  //ВИДИМОСТЬ ЭЛЕМЕНТА

  const visibilityElement = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Document/?id=${choosenElement}`,
      { method: "PUT" }
    ).then((response) => {
      getElements();
      alert("Элемент скрыт");
    });
  };

  function addElement() {
    let postObj = new Object();
    let file = window.document.getElementById("fileImg").files[0];
    if (file == undefined) {
      alert("Вы не добавили изображение");
      return;
    }
    getBase64(file).then((data) => {
      postObj.imgBase64 = data;
      postObj.imgExtension = file.name.split(".").pop();
      let title = window.document.getElementById("title__value").value;
      if (title == "") {
        alert("Вы не добавили заголовок");
        return;
      }
      let position1 = window.document.getElementById("position__elem").value;
      console.log(postObj);
      let file1 = window.document.getElementById("fileImg1").files[0];
      if (file1 == undefined) {
        alert("Вы не добавили файл");
        return;
      }
      let postObj1 = new Object();
      getBase64(file1).then((data) => {
        postObj1.docBase64 = data;
        postObj1.docExtension = file1.name.split(".").pop();

        fetch(`https://cbt-version.ru/UltronAdminApi/Document/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            title: title,
            img: postObj,
            doc: postObj1,
            position: position1,
          }),
        }).then((reponse) => {
          getElements();
          alert("Элемент успешно добавлен");
        });
      });
    });
  }

  const changeElement = () => {
    let newTitle = window.document.getElementById("changeTitle").value;

    let postObj = new Object();
    let file = window.document.getElementById("changeImg").files[0];
    if (file == undefined) {
      alert("Вы не добавили изображение");
      return;
    }
    getBase64(file).then((data) => {
      postObj.imgBase64 = data;
      postObj.imgExtension = file.name.split(".").pop();
      let file1 = window.document.getElementById("changeImg1").files[0];
      if (file1 == undefined) {
        alert("Вы не добавили файл");
        return;
      }
      let postObj1 = new Object();
      getBase64(file1).then((data) => {
        postObj1.docBase64 = data;
        postObj1.docExtension = file1.name.split(".").pop();
        fetch(
          `https://cbt-version.ru/UltronAdminApi/Document/?id=${choosenElement}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Accept: "application/json",
            },
            body: JSON.stringify({
              title: newTitle,
              img: postObj,
              doc: postObj1,
            }),
          }
        ).then((response) => {
          getElements();
          alert("Элемент успешно изменен");
          isShowModalChangeElement(false);
        });
      });
    });
  };

  const deleteElement = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Document/?id=${choosenElement}`,
      {
        method: "DELETE",
      }
    ).then((response) => {
      if (response.status == 200) {
        alert("Элемент успешно удален");
        isShowModalDelete(false);
        getElements();
        setChoosenElement(10000);
      }
    });
  };
  useEffect(() => {
    getElements();
  }, []);
  return (
    <div className="dbDocuments_container_main">
      {showModalChange && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Введите новую позицию элемента</p>
            <input
              type="text"
              id="newPosition"
              style={{
                border: errorPosition && "2px solid red",
              }}
            />
            <div>
              <button
                onClick={() => {
                  changePositionELement();
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalChange(false);
                }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      {showModalDelete && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Вы действительно хотите удалить этот элемент?</p>
            <div>
              <button
                onClick={() => {
                  deleteElement();
                }}
              >
                Удалить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalDelete(false);
                }}
              >
                Оставить
              </button>
            </div>
          </div>
        </div>
      )}
      {showModalChangeElement && (
        <div className="modal__delete">
          <div className="modal__delete__body">
            <p>Укажите заголовок</p>
            <input type="text" id="changeTitle" />
            <p>Укажите новое изображение</p>
            <input type="file" id="changeImg" />
            <p>Укажите новый файл</p>
            <input type="file" id="changeImg1" />
            <div>
              <button
                onClick={() => {
                  changeElement();
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  background: "#fff",
                  color: "black",
                  border: "2px solid #ab84fd",
                }}
                onClick={() => {
                  isShowModalChangeElement(false);
                }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="dbDocuments_container">
        <div className="database_title">
          Вы редактируете раздел <br />
          "документы"
          <img className="mainRightLine" src={mainRightLine} />
        </div>
        <div className="database_content">
          <div className="phone_page">
            <div className="phone_page_box_white_background">
              <div className="phone_page_box">
                <div className="phone_page_button_onMain">
                  <img className="arrowButtonBack" src={arrowButtonBack} />
                  <p className="phone_page_button_onMain_title text_transform">
                    база знаний
                  </p>
                </div>
                <p className="phone_page_title">документы</p>
                <div className="phone_page_content">
                  {arrData &&
                    arrData.length > 0 &&
                    arrData.map((elem, index) => {
                      return (
                        <div
                          className="phone_page_content_part"
                          style={{
                            opacity: elem.VISIBILITY == "0" && "0.5",
                            border:
                              choosenElement == elem.ID && "3px solid #ab84fd",
                            borderRadius: choosenElement == elem.ID && "0.7vw",
                          }}
                          key={index}
                          onClick={() => setChoosenElement(elem.ID)}
                        >
                          <img
                            className="chooseSectionImgDocs"
                            src={"UltronAdminApi/img/" + elem.IMG_SRC}
                          />
                          <div className="phone_page_content_part_number_docs">
                            <p className="phone_page_content_part_title_docs text_transform">
                              {elem.TITLE}
                            </p>
                            <a
                              style={{ textDecoration: "none" }}
                              href={"UltronAdminApi/doc/" + elem.FILE_SRC}
                              target="_blank"
                              className="add_section_button_placeholder text_transform"
                            >
                              смотреть
                            </a>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="phone_page_outside_buttons">
              <div
                className="outside_buttons_box"
                onClick={() => {
                  isShowModalChangeElement(true);
                }}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsChange}
                  />
                </div>
                <p className="outside_buttons_title text_transform">изменить</p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => isShowModalChange(true)}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsPosition}
                  />
                </div>
                <p className="outside_buttons_title text_transform">позиция</p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => visibilityElement()}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsVisibility}
                  />
                </div>
                <p className="outside_buttons_title text_transform">
                  видимость
                </p>
              </div>
              <div
                className="outside_buttons_box"
                onClick={() => {
                  isShowModalDelete(true);
                }}
              >
                <div className="outside_buttons_img">
                  <img
                    className="outsideButtonsImg"
                    src={outsideButtonsDelete}
                  />
                </div>
                <p className="outside_buttons_title text_transform">удалить</p>
              </div>
            </div>
          </div>
          <DataBaseAddPageView />
          <div
            className="database_right_settings"
            style={{ marginTop: "-14vw" }}
          >
            <div className="right_settings_title_box">
              <p className="right_settings_title_title">
                Введите
                <br />
                заголовок
              </p>
              <input
                id="title__value"
                className="right_settings_title_textarea text_transform"
              ></input>
            </div>
            <div className="right_settings_image">
              <p className="right_settings_image_title">Файлы</p>
              <div className="right_settings_image_dragNdrop">
                <img className="dragNdropImg" src={dragNdropImg} />
                <p className="right_settings_image_dragImg text_transform">
                  перетащите изображение...
                </p>
                <p className="right_settings_image_or text_transform">или</p>
                <input
                  type="file"
                  id="fileImg"
                  style={{
                    display: "none",
                  }}
                />

                <label
                  htmlFor="fileImg"
                  className="right_settings_image_load_button text_transform"
                >
                  загрузить
                </label>
              </div>
              <div className="right_settings_image_dragNdrop">
                <img className="dragNdropImg" src={dragNdropImg} />
                <p className="right_settings_image_dragImg text_transform">
                  Добавьте файл
                </p>
                <p className="right_settings_image_or text_transform">или</p>
                <input
                  type="file"
                  id="fileImg1"
                  style={{
                    display: "none",
                  }}
                />

                <label
                  htmlFor="fileImg1"
                  className="right_settings_image_load_button text_transform"
                >
                  загрузить
                </label>
              </div>
            </div>
            <div
              className="right_settings_position"
              style={{ margin: "1vw 0" }}
            >
              <p className="right_settings_position_title text_transform">
                позиция
              </p>
              <input
                type="text"
                id="position__elem"
                className="right_settings_position_position_number text_transform"
              ></input>
            </div>
            <button
              className="right_settings_save_changes_button text_transform"
              onClick={() => {
                addElement();
              }}
            >
              сохранить изменения
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
